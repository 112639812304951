<script setup lang="ts">
import { useSlots } from "vue"

interface Props {
  unpadded?: boolean // no body padding
}

const props = withDefaults(defineProps<Props>(), {
  unpadded: false,
})

const slots = useSlots()

const bodyClasses = computed(() => {
  return props.unpadded ? "" : "p-8"
})
</script>

<template>
  <section class="bg-white border border-slate-200 shadow-01 rounded">
    <div :class="bodyClasses">
      <slot></slot>
    </div>
    <div
      v-if="slots.subsection"
      :class="bodyClasses"
      class="border-t border-slate-100 pt-8"
    >
      <slot name="subsection"></slot>
    </div>
    <div
      v-if="slots.footer"
      class="bg-slate-25 p-4 border-t rounded-b-lg border-slate-100"
    >
      <slot name="footer"></slot>
    </div>
  </section>
</template>
